<template>
  <div class="page_container">
    <!-- 静态图片 -->
    <div class="about_banner">
      <img src="../../assets/image/about/join.jpg" alt />
    </div>
    <!-- 导航条 -->
    <div class="about_nav">
      <div class="about_nav_box">
        <ul class="about_nav_detail">
          <li>
            <router-link to="/about">公司简介</router-link>
          </li>
          <li>
            <router-link to="/prize">荣誉资质</router-link>
          </li>
          <li>
            <router-link to="/position">加入我们</router-link>
          </li>
          <li>
            <router-link to="/contact">联系我们</router-link>
          </li>
        </ul>
      </div>
    </div>
    <!-- 发布职位 -->
    <div class="job_box">
      <el-container>
        <el-aside style="width:24%">
          <el-menu class="el-menu-vertical-demo">
            <el-menu-item @click="goBePengding" index="pending1">
              <i class="el-submenu__icon-arrow el-icon-arrow-right"></i>
              <span slot="title">销售</span>
            </el-menu-item>
            <el-menu-item @click="goBePengding" index="pending2">
              <i class="el-submenu__icon-arrow el-icon-arrow-right"></i>
              <span slot="title">设计</span>
            </el-menu-item>
            <el-menu-item @click="goPosition" index="position">
              <i class="el-submenu__icon-arrow el-icon-arrow-right"></i>
              <span slot="title">研发</span>
            </el-menu-item>
            <el-menu-item @click="goBePengding" index="pending3">
              <i class="el-submenu__icon-arrow el-icon-arrow-right"></i>
              <span slot="title">市场</span>
            </el-menu-item>
            <el-menu-item @click="goBePengding" index="pending4">
              <i class="el-submenu__icon-arrow el-icon-arrow-right"></i>
              <span slot="title">产品与运营</span>
            </el-menu-item>
            <el-menu-item @click="goBePengding" index="pending5">
              <i class="el-submenu__icon-arrow el-icon-arrow-right"></i>
              <span slot="title">实施与运维</span>
            </el-menu-item>
          </el-menu>
        </el-aside>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goPosition () {
      this.$router.push('position')
    },
    goBePengding () {
      this.$router.push('bepending')
    }
  }
}
</script>

<style scoped>
.el-aside {
  margin-right: 4%;
}
.el-main {
  width: 72%;
  padding: 0;
}
.el-menu {
  border-right: 0;
}
.el-menu-item {
  font-size: 18px;
  margin-bottom: 30px;
  line-height: 51px;
  letter-spacing: 0px;
  color: #333333;
}
.el-menu-item i {
  display: none;
}
.el-menu-item.is-active i {
  display: block;
}
.el-menu-item.is-active {
  color: #4e92db;
  background: none;
  border: solid 0.5px #707070;
  opacity: 0.5;
}
.job_detail {
  border: solid 1px #999999;
  opacity: 0.6;
}
</style>
